function _isMobile() {//判断是否为手机端
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
if (_isMobile()) {
    //设置 rem 函数
    
//  import 'lib-flexible/flexible.js'
    function setRem() {
        // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
        const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
        //得到html的Dom元素
        const htmlDom = document.getElementsByTagName('html')[0];
        //设置根元素字体大小
        htmlDom.style.fontSize = htmlWidth / 23.439 + 'px';
    }
    // 初始化
    setRem();
    // 改变窗口大小时重新设置 rem
    window.onresize = function () {
        setRem();
    };
}