import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/mobile/index.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path:'/product/:id',
    name:'product',
    component:() => import('../views/mobile/product.vue')
  },
  {
    path: '/release',
    name: 'release',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/release.vue')
  },
  {
    path:'/experience',
    name:'experience',
    component:() => import('../views/mobile/experience.vue')
  },
  {
    path: '/about',
    name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    component: () => import('../views/mobile/about.vue')
  },
  {
    path:'/customMade/:id',
    name:'customMade',
    component:() => import('../views/mobile/customMade.vue')
  },
  {
    path:'/regulation',
    name:'regulation',
    component:() => import('../views/mobile/regulation.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
