<template>
    <div class="totop" :class="{hastotop:hasToTop}">
        <div class="totopbox" 
            @click="getTop"
            @mouseenter="change = 1" 
            @mouseleave="change = 0"
            :class="{changecolor:change == 1}">
            <img :src="img1" alt="" v-if="change == 0">
            <img :src="img2" alt="" v-if="change == 1">
            <div class="totopboxstr">回到顶部</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            img1:require('@/assets/img/pcImg/up.png'),
            img2:require('@/assets/img/pcImg/up／hover.png'),
            change:0,
            top0:0,
        }
    },
    props:["hasToTop"],
    methods:{
        getTop(){
         document.body.scrollTop = document.documentElement.scrollTop = 0
       }
    }
}
</script>

<style lang="less" scoped>
.totop{
    .totopbox{
        width: 60px;
        height: 60px;
        background: #E9F2FF;
        box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        position: fixed;
        bottom: 120px;
        right: 200px;
        z-index: 100;
        img{
            margin: 15px 0 0 19px;
        }
        .totopboxstr{
            font-size: 12px;
            font-weight: 400;
            color: #697598;
            margin: 5px 0 0 5px;
        }
    }
    .changecolor{
        background: #207BFA;
        cursor:pointer;
        .totopboxstr{
            color: #FFFFFF;
        }
    }
}
.hastotop{
    display: none;
}
</style>