<template>
    <div class="left">
        <div class="head1">
            <img :src="headImg1" alt="">
            <p>
                泰科医键通 <br>
                后勤一体化信息平台
            </p>
        </div>
        <div class="nav">
            <ul v-for="(item,index) in nav.slice(0, 12)" :key="index">
                <li 
                    class="navbox"
                    @click="navBtn(item.id)"
                    @mouseenter="mouseNav = item.id"
                    @mouseleave="mouseNav = 0"
                    :class="{changeNavBoxColor:clickNav == item.id||mouseNav == item.id}">
                    <img :src="item.icon" alt="">
                    <span>{{item.name}}</span>
                    <img :src="item.down" alt="" v-if="!childListCtrl" class="updown">
                    <img :src="item.up" alt="" v-if="childListCtrl" class="updown">
                    <ul v-show="childListCtrl"
                        class="childnav">
                        <li v-for="(item2,index2) in item.childlist" :key="index2"
                            @click="navBtn(item2.id),childnavBtn(item2.id)"
                            @mouseenter="mouseChildNav = item2.id"
                            @mouseleave="mouseChildNav = 0"
                            :class="{changeChildNavBoxColor:clickChildNav == item2.id||mouseChildNav == item2.id}">
                            <span>{{item2.name}}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="head2">
            <img :src="headImg2" alt="">
            <p>定制服务</p>
        </div>
        <div class="nav">
            <ul>
                <li v-for="(item,index) in nav.slice(12, 14)" :key="index"
                    class="navbox"
                    @click="nav2Btn(item.id)"
                    @mouseenter="mouseNav = item.id"
                    @mouseleave="mouseNav = 0"
                    :class="{changeNavBoxColor:clickNav == item.id||mouseNav == item.id}">
                    <img :src="item.icon" alt="">
                    <span>{{item.name}}</span>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
export default {
    name:'',
    data(){
        return{
            clickNav:1,
            mouseNav:0,
            clickChildNav:0,
            mouseChildNav:0,
            childListCtrl:false,
            headImg1:require('@/assets/img/pcImg/leftnavImg/sidebar／icon／ty.png'),
            headImg2:require('@/assets/img/pcImg/leftnavImg/sidebar／icon／ser.png'),
            nav:[
                {
                    id:1,
                    name:'医废管理',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-yf.png"),
                    childlist:[],
                },
                {
                    id:2,
                    name:'输送系统',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-ss.png"),
                    childlist:[],
                },
                {
                    id:3,
                    name:'维修系统',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-wx.png"),
                    up:require("@/assets/img/pcImg/leftnavImg/up.png"),
                    down:require("@/assets/img/pcImg/leftnavImg/down.png"),
                    childlist:[
                        {
                            id:31,
                            name:'维修报障',
                        },
                        {
                            id:32,
                            name:'维修巡检',
                        },
                        {
                            id:33,
                            name:'后勤维保',
                        }
                    ]
                },
                {
                    id:4,
                    name:'保洁系统',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-bj.png"),
                    childlist:[],
                },
                {
                    id:5,
                    name:'人员管理',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-ry.png"),
                    childlist:[],
                },
                {
                    id:6,
                    name:'考勤打卡',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-kq.png"),
                    childlist:[],
                },
                {
                    id:7,
                    name:'停车场管理',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-tc.png"),
                    childlist:[],
                },
                {
                    id:8,
                    name:'智能卫生间引导系统',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-zn.png"),
                    childlist:[],
                },
                {
                    id:9,
                    name:'服务满意度管理',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-fw.png"),
                    childlist:[],
                },
                {
                    id:10,
                    name:'保安巡逻',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-ba.png"),
                    childlist:[],
                },
                {
                    id:11,
                    name:'被服管理',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-bf.png"),
                    childlist:[],
                },
                {
                    id:12,
                    name:'垃圾分类小游戏',
                    icon:require("@/assets/img/pcImg/headImg/nav-icon-lj.png"),
                    childlist:[],
                },
                {
                    id:13,
                    name:'软件定制',
                    icon:require("@/assets/img/pcImg/leftnavImg/sidebar／icon／Custom.png"),
                    childlist:[],
                },
                {
                    id:14,
                    name:'动漫设计',
                    icon:require("@/assets/img/pcImg/leftnavImg/sidebar／icon／anime.png"),
                    childlist:[],
                }
            ],
        }
    },
    methods:{
        navBtn(id){
           this.clickNav = id
           if(id == 3){
               this.childListCtrl = !this.childListCtrl
           }else if(id>30){
               this.childListCtrl = true
           }
           else{
               this.childListCtrl = false
               this.clickChildNav = 0
                this.$router.push({
                    path:`/product/${id}`,
                })
           }
        },
        childnavBtn(id){
            this.clickChildNav = id
            this.$router.push({
                path:`/product/${id}`,
            })

        },
        nav2Btn(id){
            this.clickNav = id
            this.childListCtrl = false
            this.clickChildNav = 0
            this.$router.push({
                 path:`/customMade/${id}`,
            })
           
        },
    },
    watch:{
        $route:{
            handler(){
                this.clickNav = this.$route.params.id
                if(this.$route.params.id >30){
                    this.childListCtrl = !this.childListCtrl
                    this.clickNav = 3
                    this.clickChildNav = this.$route.params.id
                }else{
                    this.childListCtrl = false
                    this.clickNav = this.$route.params.id
                }
            },
            // console.log(this.$route.params.id);
            immediate: true
        },
    },
}
</script>

<style lang="less" scoped>
.left{
    z-index: 100;
    width: 287px;
    background: #FFFFFF;
    box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-bottom: 80px;
    float: left;
    .head1{
        width: 287px;
        height: 66px;
        background: #FAFBFE;
        border-radius: 3px 3px 0px 0px;
        padding-top: 14px;
        margin-bottom: 10px;
        img{
            margin: 0 10px 0 20px;
            float: left;
        }
        p{
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;
            color: #31384E;
        }
        
    }
    .nav{
        ul{
            .navbox{
                width: 257px;
                // height: 40px;
                background: #fff;
                border-radius: 25px 0px 0px 25px;
                margin: 0 0 10px 30px;
                cursor:pointer;
                img{
                    margin: 0 10px 5px 20px;
                    vertical-align:middle
                }
                span{
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #000000;
                }
                ul{
                    li{
                        display: block;
                    }
                }
                .updown{
                    margin: 0 0 0 10px;
                }
            }
            .changeNavBoxColor{
                background: #EFF6FF;
                span{
                    color: #207BFA;
                }
            }
            .childnav{
                span{
                    color: black;
                    // background-color: brown;
                    margin-left: 59px;
                }
                .changeChildNavBoxColor{
                    span{
                        color: #207BFA;
                    }
                }
            }
        }
        
    }
    .head2{
        width: 287px;
        height: 70px;
        background: #FAFBFE;
        border-radius: 3px 3px 0px 0px;
        margin-bottom: 10px;
        img{
            float: left;
            margin: 15px 10px 15px 20px;
        }
        p{
            float: left;
            font-size: 16px;
            font-weight: bold;
            color: #31384E;
            margin-top: 25px;
        }
    }
}
</style>