<template>
    <div class="pc-hotline">
        <div class="bg"> </div>
        <!-- <img src="../../assets/img/hotline/1.gif" alt=""> -->
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less">
    .pc-hotline{
        position: fixed;
        left: 0;
        bottom: 0px;
        z-index: 99;
        width: 100%;
        height: 44px;
        background: linear-gradient(45deg, #2373ee 50%, #1b53e7 51%);
        .bg  {
            width: 100%;
            height: 60px;
            background-image: url(../../assets/img/hotline/1.gif);
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 100%;
            margin: -15px auto 0;
        }
    }
    #app {
        padding-bottom: 60px;
        #app {
            padding: 0;
        }
    }
</style>