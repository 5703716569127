<template>
  <div id="app">
    <heads :changeTop="changetop" :closeTop="closetop"></heads>
    <router-view/>
    <totops :hasToTop="hastotop"/>
  </div>
</template>

<script>
import Heads from '@/components/pc/head&login.vue'
import Totops from '@/components/pc/totop.vue'
export default {
  name:'App',
  components:{
    Heads,
    Totops
  },
  data(){
    return{
      changetop:false,
      hastotop:true,
      closetop:false,
    }
  },
   watch:{
        $route:{
            handler(){
                // console.log(this.$route.name);
                if(this.$route.name == 'download' || this.$route.name == 'regulation'){
                  this.closetop = true
                }else{
                  this.closetop = false
                }
            },
            immediate: true
        },
    },
    
  mounted() {
       window.addEventListener('scroll',this.handleScroll);   // 监听（绑定）滚轮滚动事件
   },
  methods:{
       handleScroll() {
         if(this.$route.name != 'regulation'){
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop > 100){
                // console.log(scrollTop);
                this.changetop = true
                this.hastotop = false
            }else{
                // console.log(1)
                this.changetop = false
                this.hastotop = true
            }
         }
       },
   },
}
</script>

<style lang="less">
// head:z-index:100
*{
  padding: 0;
  margin: 0;
  list-style: none;
  a{
        text-decoration: none;
    }
  input{
    border: none;
    outline:none;
  }
  button{
    outline:none;
  }
}
</style>
