<template>
    <div>
       <div class="main1">
            <img :src="bg1" alt="" class="bg1">
            <div class="bg1str">
                <h2>医键通后勤管理系统</h2>
                <p>打造智慧后勤，为后勤便捷管理而设计</p>
                <router-link to="/experience"><button>立即体验</button></router-link>
            </div>
            <div class="list1">
                <h2>简单易用，让传达更高效，让工作更便捷</h2>
                <ul>
                    <li v-for="(item,index) in list1" :key="index">
                       <img :src="item.icon" alt="">
                       <h3>{{item.title}}</h3>
                       <p>{{item.part1}}</p>
                       <p>{{item.part2}}</p>
                    </li>
                </ul>
            </div>
       </div>
       <div class="main2">
            <img :src="bg2" alt="" class="bg2">
            <div class="bg2str">
                <h2>专注后勤信息化管理解决方案</h2>
                <p>支持多种后勤服务按需组合，提供功能个性化定制，适用不同类型医院需求</p>
            </div>
            <div class="list2">
                <div class="list2-box" v-for="(item,index) in list2" :key="index" :class="{rightimg:index%2!=0}">
                    <div class="list2img">
                        <img :src="item.img" alt="">
                    </div>
                    
                    <div class="list2str">
                        <!-- <h3></h3>
                        <h4></h4>
                        <p></p> -->
                        <div v-html="item.content"></div>
                        <button @click="sendId(item.id)">了解更多&nbsp;&nbsp;⇾</button>
                    </div>
                </div>
            </div>
            <router-link to="/product/1"><button class="main2btn">查看更多产品</button></router-link>
       </div>
       <div class="main3">
           <img :src="bg3" alt="" class="bg3">
           <div class="list3box">
               <div class="list3str">
                   <h2>用心设计、按需定制、贴心服务</h2>
                   <p>研发特点</p>
               </div>
               <div class="list3">
                   <ul>
                       <li v-for="(item,index) in list3" :key="index">
                           <img :src="item.img" alt="">
                           <h3>{{item.title}}</h3>
                           <div v-html="item.content"></div>
                       </li>
                   </ul>
               </div>
           </div>
       </div>
       <div class="main4">
           <div class="list4title">
               <br><br><br>
                <h2>全心全意为医院提供便捷、高效后勤支持服务</h2><br>
                <p>合作伙伴</p>
           </div>
           <br><br>
           <div class="list4">
               <ul>
                   <li v-for="(item,index) in list4" :key="index">
                       <img :src="item.img" alt="">
                       <span>{{item.content}}</span>
                   </li>
               </ul>
           </div>
       </div>
       <div class="main5">
           <div class="main5box">
               <img :src="img" alt="">
               <h2>开启高效后勤管理体验</h2>
               <p>申请软件试用</p>
               <router-link to="/experience"><button>立即申请</button></router-link>
           </div>
       </div>
       <foots/>
       <hotline/>
    </div>
</template>

<script>
import foots from '@/components/pc/foot'
import hotline from '@/components/pc/hotline.vue'
export default {
    name:'Index',
    components:{
        foots,
        hotline
    },
    data(){
        return{
            bg1:require('@/assets/img/pcImg/indexImg/bg1.png'),
            bg2:require('@/assets/img/pcImg/indexImg/bg2.png'),
            bg3:require('@/assets/img/pcImg/indexImg/bg3.png'),
            img:require('@/assets/img/pcImg/indexImg/立即申请.png'),
            list1:[
                {
                    icon:require("@/assets/img/pcImg/indexImg/list1/jd_icon1.png"),
                    title:'工作有计划',
                    part1:'上传下达工作任务，',
                    part2:'全局制定，落实到人'
                },
                {
                    icon:require("@/assets/img/pcImg/indexImg/list1/jd_icon2.png"),
                    title:'功能易操作',
                    part1:'注重界面设计交互，',
                    part2:'让操作更便捷'
                },
                {
                    icon:require("@/assets/img/pcImg/indexImg/list1/jd_icon3.png"),
                    title:'统计人性化',
                    part1:'摒弃繁杂无序、分析整理',
                    part2:'只给您看重要的数据'
                },
                {
                    icon:require("@/assets/img/pcImg/indexImg/list1/jd_icon4.png"),
                    title:'无纸化办公',
                    part1:'致力打造最合身的',
                    part2:'企业定制软件'
                },
                {
                    icon:require("@/assets/img/pcImg/indexImg/list1/jd_icon5.png"),
                    title:'多端能互动',
                    part1:'移动端、电脑端',
                    part2:'随时随地地全局掌控'
                },
                {
                    icon:require("@/assets/img/pcImg/indexImg/list1/jd_icon6.png"),
                    title:'数据重安全',
                    part1:'建立云端信息网络，',
                    part2:'全时段为数据保驾护航'
                }
            ],
            list2:[
                {
                    id:1,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1068.png"),
                    content:`<h2>医废管理</h2>
                            <h4>医疗废物转运全生命周期可溯源管理</h4>
                            <p>通过规范医废转运流程,实现医废称重一体,从而提升医废标签清
                            晰、规范,数据精准。<br><br>
                            科室相关负责人可在微信公众号上进行医废交接,核对无误后在
                            线签名确认医废。<br><br>
                            数据云存储,丰富的数据统计含科室对比、详细数据、异常报警等,
                            可选择条形图或饼图数据可以导出excel
                            </p>`
                },
                {
                    id:2,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1069.png"),
                    content:`<h2>输送系统</h2>
                            <h4>完整的维修工单管理、维修派工自动化</h4>
                            <p>临床科室选择输送类型、输送项目简单操作即可快速下单<br><br>
                            对接了his的医院,可一键发布医嘱单<br><br>
                            输送员工手机APP、微信查看输送工单情况,在线接单、完成<br><br>
                            管理人员网页端实时查看输送单完成情况数据，还可以导出Excel
                            </p>`
                },
                {
                    id:31,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1070.png"),
                    content:`<h2>维修报障</h2>
                            <h4>在线周期性有计划的制订巡检任务</h4>
                            <p>管理人员可在网页后台发起和查看维修任务并进行指派维修员,实现
                            集中监控维修工单<br><br>
                            支持智能抢单,网页模式与APP模式结合派工,提高了派工管理人员
                            的工作效率<br><br>
                            维修员工app接单、查看维修单详情、拍照上传完成图片<br><br>
                            支持报修人在网页/微信上实时查看完成进度及详细情况
                            </p>`

                },
                {
                    id:32,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1071.png"),
                    content:`<h2>维修巡检</h2>
                            <h4>实现在线周期性有计划的制定巡检任务</h4>
                            <p>通过网页端建立巡检计划,手机接收巡检任务,将传统纸质表格电子化
                            </p>
                            <h4>蓝牙定位巡检,巡检异常警报提醒</h4>
                            <p>巡检人员到达指定地点搜索到蓝牙对应的巡检表方可填写<br>
                            对巡检异常内容可拍照上传图片,管理人员可收到异常警报提醒
                            </p>`
                },
                {
                    id:4,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1072.png"),
                    content:`<h2>保洁系统</h2>
                            <h4>保洁问题快捷申报 问题多发区域分析提升管理质量</h4>
                            <p>用户可在微信发起保洁单<br><br>
                            员工每日巡查发现突发问题可在APP上登记问题和处理问题单支持拍
                            照上传图片<br><br>
                            管理部门可查看每日巡查次数、巡查人员发单排名、巡查问题等数
                            据,有导出excel表格和保存图片功能
                            </p>`
                },
                {
                    id:5,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1073.png"),
                    content:`<h2>人员管理</h2>
                            <h4>人员信息与考勤、社保公积金联动</h4>
                            <p>可下载模板批量上传人员信息档案,有审核功能,实现人员档案电子
                            化管理<br><br>
                            人员异动登记,支持异动选项内容自定义<br><br>
                            与考勤联动,可查看人员考勤出勤详细和月度汇总<br><br>
                            与社保公积金联动,可查看人员每月人员社保公积金情况
                            </p>`
                },
                {
                    id:6,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1074.png"),
                    content:`<h2>考勤打卡</h2>
                            <h4>人脸识别快速打卡</h4>
                            <p>员工可在app上绑定人脸识别,系统自动识别当前地址进行打卡,可
                            设置打卡提醒
                            </p>
                            <h4>外勤多地打卡统一管理</h4>
                            <p>支持多地多医院打卡,考勤统一管理,外勤员工也可考勤</p>
                            <h4>考勤数据统计</h4>
                            <p>查看考勤数据,团队出勤迟到、早退、请假、外勤数据一目了然,便
                            于计算工资
                            </p>`
                },
                {
                    id:7,
                    img:require("@/assets/img/pcImg/indexImg/list2/组1075.png"),
                    content:`<h2>停车场管理</h2>
                            <h4>车辆进出场记录及费用核算</h4>
                            <p>可设定按医院和物价局规定的进场免费时间和实际看病情况自行设定
                            收费标准
                            </p>
                            <h4>在线缴费</h4>
                            <p>用户可微信扫码查看停车费用进行缴费
                            车场收费人员可在系统内查看用户缴费情况,缴费成功后自动抬杆放
                            </p>
                            <h4>完善的数据统计,有利于提高车场合理使用率</h4>
                            <p>数据自动分析哪个地区的车辆进场较多,24小时内高峰进出场时间段
                            分析等利于提高停车场合理使用率
                            </p>`
                }
            ],
            list3:[
                {
                    img:require("@/assets/img/pcImg/indexImg/list3/td-icon1.png"),
                    title:'按使用者立场去设计产品',
                    content:`<p>适配中老年用户，字体放大，减少操作、方便快捷</p>`
                },
                {
                    img:require("@/assets/img/pcImg/indexImg/list3/td-icon2.png"),
                    title:'按用户习惯定制不同操作场景',
                    content:`<p><span>支持多端操作</span>灵活运用</p>`
                },
                {
                    img:require("@/assets/img/pcImg/indexImg/list3/td-icon3.png"),
                    title:'量身定制个性化管理工具',
                    content:`<p>主动建立工作计划，接收推送提醒，</p>
                            <p>随时随地保持高效有序工作</p>`
                },
                {
                    img:require("@/assets/img/pcImg/indexImg/list3/td-icon4.png"),
                    title:'组成生态圈，让后勤数据互通',
                    content:`<p>结合医院的实际，把关联信息串联在一起，</p>
                            <p>组成一个生态圈如：用户可以查看维修人员的员工信息</p>
                            <p>（该信息来源于人员管理系统）</p>`
                },
                {
                    img:require("@/assets/img/pcImg/indexImg/list3/td-icon5.png"),
                    title:'特色培训，设计动漫培训视频',
                    content:`<p>简单易懂、有效提升了员工的操作通过率</p>`
                },
                {
                    img:require("@/assets/img/pcImg/indexImg/list3/td-icon6.png"),
                    title:'在线支持服务、实时跟踪',
                    content:`<p>组织在线运营，实时跟踪问题并及时改进，</p>
                            <p>硬件维护服务，并定期组织现场培训</p>`
                }
            ],
            list4:[
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo1.png"),
                    content: '中山大学肿瘤防治中心'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo2.png"),
                    content: '中山大学孙逸仙纪念医院'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo3.png"),
                    content: '中山大学附属第三医院'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo4.png"),
                    content: '中山大学附属第六医院'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo5.png"),
                    content: '广州医科大学附属肿瘤医院'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo6.png"),
                    content: '广东省妇幼保健院'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo7.png"),
                    content: '广州市妇女儿童医疗中心'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo8.png"),
                    content: '广州市儿童医院'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo9.png"),
                    content: '广州市妇婴医院'
                },
                // {
                //     img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo10.png"),
                //     content: '白云区石井人民医院'
                // },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo11.png"),
                    content: '南方医科大学第三附属医院'
                },
                {
                    img: require("@/assets/img/pcImg/indexImg/list4/hospital-logo12.png"),
                    content: '广州市中医药大学第三附属医院'
                }
            ]

        }
    },
    methods:{
        sendId(id){
            // console.log(id);
            this.$router.push({
                path:`/product/${id}`,
            })
        }
    }
}
</script>

<style lang="less" scoped>
.main1{
    width: 100%;
    height: 802px+218px;
    .bg1{
        width: 100%;
        height: 802px;
    }
    .bg1str{
        width: 414px;
        position: absolute;
        top: 230px;
        left: 50%;
        margin-left: 112px;
        color: #fff;
        h2{
            font-size: 46px;
            font-weight: 400;
        }
        p{
            font-size: 24px;
            font-weight: 400;
            opacity: 0.6;
            margin: 12px 0 42px 0;
        }
        button{
            width: 200px;
            height: 60px;
            background: #FFFFFF;
            box-shadow: 5px 12px 38px rgba(16, 38, 220, 0.45);
            border-radius: 35px;
            border: none;
            font-size: 20px;
            font-weight: bold;
            color: #207BFA;
            cursor:pointer;
        }
        button:hover{
            box-shadow:none;
        }
    }
    .list1{
        width: 1200px;
        height: 378px;
        background: #FFFFFF;
        box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        position: absolute;
        left: 50%;
        top: 602px;
        margin: 0 0 0 -600px;
        // background-color: blanchedalmond;
        h2{
            margin: 40px 0 60px 348px;
            font-size: 28px;
            font-weight: bold;
            color: #207BFA;
        }
        ul{
            // background-color: blanchedalmond;
            li{
                // background-color: cadetblue;
                display: inline-block;
                width: 200px;
                text-align: center;
                img{
                    width: 108px;
                    height: 98px;
                    margin-bottom: 20px;
                }
                h3{
                    font-size: 18px;
                    font-weight: bold;
                    color: #31384E;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    color: #697598;
                }
            }
        }
    }
}
.main2{
    width: 100%;
    height: 4751px;
    .bg2{
        width: 100%;
        height: 4751px;
        z-index: -5;
        position: absolute;
    }
    .bg2str{
        // background-color: cadetblue;
        text-align: center;
        position: absolute;
        margin-left: -297px;
        left: 50%;
        h2{
            font-size: 28px;
            font-weight: bold;
            color: #31384E;
            margin: 40px 0 10px 0;
        }
        p{
            font-size: 18px;
            font-weight: 400;
            color: #697598;
        }
    }
    .list2{
        // background-color: cornsilk;
        width: 1200px;
        position: absolute;
        left: 50%;
        margin-left: -600px;
        top: 1254px;
        .list2-box{
            width: 1200px;
            height: 460px;
            // background-color: cadetblue;
            margin-bottom: 100px;
            .list2img{
                float: left;
                width: 640px;
                height: 460px;
                // background-color: darkgoldenrod;
                img{
                    // object-fit: contain;
                    margin: 40px 0 0 40px;
                }
            }
            .list2str{
                margin: 50px 0 0 140px;
                // background-color: darkgreen;
                float: left;
                width: 420px;
                /deep/ h2{
                    font-size: 28px;
                    font-weight: bold;
                    color: #207BFA;
                   
                }
                /deep/ h4{
                    font-size: 18px;
                    font-weight: 400;
                    color: #31384E;
                    margin: 22px 0 12px 0;
                }
                /deep/ p{
                    font-size: 14px;
                    font-weight: 400;
                    color: #697598;
                    -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
                }
                button{
                    width: 200px;
                    height: 60px;
                    background: linear-gradient(132deg, #2655EE 0%, #34B3FF 100%);
                    box-shadow: 0px 2px 16px rgba(32, 123, 250, 0.5);
                    color: #fff;
                    font-size: 18px;
                    font-weight: 400;
                    border-radius: 35px;
                    border: none;
                    margin-top: 42px;
                    cursor:pointer;
                }
                button:hover{
                    box-shadow: none;
                }
            }
        }
        .rightimg{
            .list2img{
                float: right;
                img{
                    float: right;
                    margin: 45px 30px 0 0;
                }
            }
            .list2str{
                margin: 50px 0 0 10px;
                // background-color: darkgreen;
                float: left;
                width: 420px;
                
            }
        }
        .list2-box:last-child{
            margin-bottom: 0;
        }
        
    }
    .main2btn{
        width: 200px;
        height: 60px;
        border: 1px solid #207BFA;
        opacity: 1;
        border-radius: 96px;
        background-color: #fff;
        font-size: 18px;
        font-weight: 400;
        color: #207BFA;
        cursor:pointer;
        position: absolute;
        top: 5791px;
        left: 50%;
        margin: -100px;
    }
    .main2btn:hover{
        color: #FFFFFF;
        background: #207BFA;
    }
}
.main3{
    width: 100%;
    height: 1135px;
    .bg3{
        width: 100%;
        height: 1135px;
        position: absolute;
        z-index: -5;
    }
    .list3box{
        width: 1200px;
        height: 945px;
        background: #FFFFFF;
        box-shadow: 0px 10px 42px rgba(23, 35, 75, 0.08);
        border-radius: 6px;
        position: relative;
        top: 80px;
        left: 50%;
        margin-left: -600px;
        .list3str{
            text-align: center;
            float: left;
            // background-color: darkgreen;
            margin: 50px 0 0 407px;
            h2{
                font-size: 28px;
                font-weight: bold;
                color: #31384E;
                margin-bottom: 12px;
            }
            p{
                font-size: 18px;
                font-weight: 400;
                color: #697598;
            }
        }
        .list3{
            // height: 819px;
            width: 1200px;
            // background-color: rgb(7, 65, 92);
            float: left;
            ul{
                margin: 94px 0 0 35px;
                li{
                    width: 380px;
                    height: 350px;
                    display: inline-block;
                    text-align: center;
                    // background-color: rgb(7, 65, 92);
                    // float: left;
                    // img{
                    //     margin-top: 94px;
                    // }
                    h3{
                        margin: 25px 0 11px 0;
                        font-size: 18px;
                        font-weight: bold;
                        color: #31384E;
                        -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
                    }
                    /deep/ p{
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 19px;
                        color: #697598;
                    }
                }
            }
        }
    }
}
.main4{
    width: 100%;
    height: 511px;
    background: #FAFBFE;
    .list4title{
        text-align: center;
        h3{
            font-size: 28px;
            font-weight: bold;
            color: #31384E;
            // margin: 50px 0 12px 0;
        }
        p{
            font-size: 18px;
            font-weight: 400;
            color: #697598;
        }
    }
    .list4{
        position: absolute;
        left: 50%;
        margin-left: -620px;
        ul{
            height: 258px;
            width: 1212px;
            // background-color: darkolivegreen;
            li{
                width: 256px;
                height: 66px;
                background: #FFFFFF;
                border-radius: 6px;
                display: inline-block;
                margin: 20px 0 0 47px;
                img{
                    vertical-align:middle;
                    margin: 11px 10px 11px 15px;
                }
                span{
                    font-size: 12px;
                    font-weight: 400;
                    color: #31384E;
                }
            }
        }
    }
    
}
.main5{
    width: 100%;
    height: 627px;
    .main5box{
        width: 1200px;
        height: 503px;
        // background-color: darkslateblue;
        position: relative;
        top: 47px;
        left: 50%;
        margin-left: -600px;
        img{
            float: left;
        }
        h2{
            float: left;
            font-size: 46px;
            font-weight: 400;
            color: #31384E;
            // margin: 160px 0 0 143px;
            margin: 160px 0 0 100px;
        }
        p{
            float: left;
            font-size: 24px;
            font-weight: 400;
            color: #31384E;
            opacity: 0.6;
            margin: 10px 0 0 100px;
        }
        button{
            float: left;
            width: 200px;
            height: 60px;
            background: linear-gradient(132deg, #2655EE 0%, #34B3FF 100%);
            box-shadow: 0px 2px 16px rgba(32, 123, 250, 0.5);
            border-radius: 30px;
            font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
            text-shadow: 0px 2px 16px rgba(32, 123, 250, 0.5);
            -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
            border: none;
            margin: 97px 0 0 -145px;
        }
        button:hover{
            box-shadow: none;
            cursor:pointer;
        }
    }
}
</style>