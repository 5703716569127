<template>
    <div class="foot">
        <div class="content">
          <p>Copyright@2016 www.ektlang.com</p>
          <p>医键通版权所有 <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备 16077822号</a></p> 
        </div>
    </div>
</template>

<script>
export default {
    name:'foots',
}
</script>

<style lang="less" scoped>
.foot{
  height: 5rem;
  background: #FAFBFE;
  border-radius: 0.1875rem;
  width: 23.4375rem;
  .content{
    text-align: center;
    color: #1D1D1D;
    font-size: 0.875rem;
    float: left;
    margin: 1.3125rem 0 0 3.5625rem;
    p{
      font-size: 0.875rem;
      font-weight: 400;
      color: #1D1D1D;
    }
    a{
       color: #1D1D1D; 
    }
  }
}
</style>