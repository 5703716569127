<template>
    <div class="hotline">
        <img src="../../assets/img/hotline/2.gif" alt="">
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less">
    .hotline{
        width: 100%;
        height: auto;
        position: fixed;
        left: 0;
        bottom: 0px;
        z-index: 99;
        font-size: 0;
        img{
            width: 100%;
            
        }
    }
</style>