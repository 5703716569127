import { createStore } from 'vuex'

export default createStore({
  state: {
    touchNav:0,
  },
  mutations: {
    // changeNavId(state,id){
    //   state.touchNav = id
    //   // console.log(state.touchNav);
    // }
  },
  actions: {
  },
  modules: {
  }
})
