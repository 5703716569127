<template>
<div>
    <div class="head" :class="{changetop:changeTop||ctrlMenu,closetop:closeTop}">
       <div class="headbox">
           <div class="logo">
               <router-link to="/"><img :src="logo" alt=""></router-link>
           </div>
           <div class="menubtn">
               <img :src="clickImg" alt="" @click="showMenu">
           </div>
       </div>
       <div class="navbg" v-show="ctrlMenu" @click="showMenu">

       </div>
       <div class="nav" v-show="ctrlMenu" >
           <ul class="nav1">
               <li v-for="(item,index) in nav" :key="index" @click="gogogo(item.id,item.link)">
                   <span>{{item.name}}</span>
                   <img :src="item.down" alt="" v-show="!item.showchild">
                   <img :src="item.up" alt="" v-show="item.showchild">
                   <ul :class="item.classname" v-show="item.showchild">
                       <li v-for="(item2,index2) in item.childlist" :key="index2"
                            :class="[item2.classname,{showgrandchild:!item2.showGrandChild}]"
                            @click.stop="gogogo(item2.id)">
                           <img :src="item2.icon" alt="">
                           <span>{{item2.name}}</span>
                           <img :src="item2.down" alt="" v-show="!item2.showGrandChild" class="ctrlicon">
                           <img :src="item2.up" alt="" v-show="item2.showGrandChild" class="ctrlicon">
                           <ul v-show="item2.showGrandChild">
                               <li v-for="(item3,index3) in item2.childlist" :key="index3" @click.stop="gogogo(item3.id)">
                                   <span>{{item3.name}}</span>
                               </li>
                           </ul>
                       </li>
                   </ul>
               </li>
           </ul>
       </div>
    </div>
    <div class="login" v-show="showlogin">
      <div class="loginbg" @click="showlogin = false">
      </div>
      <div class="loginbox">
            <h2>账号登录</h2>
            <div class="inputstr">
                <input v-model="username" type="text" name="" id="" placeholder="账号">
            </div>
            <div class="inputstr" id="passwordbox">
                <input v-model="password" :type="passwordtype" name="" id="" placeholder="密码">
                <img :src="closeeye" alt="" 
                    v-show="changeeye == false"
                    @click="changeeye = true,passwordtype = 'text'">
                <img :src="openeye" alt="" 
                    v-show="changeeye == true"
                    @click="changeeye = false,passwordtype = 'password'">
            </div>
            <div class="inputput" @click="login">登录</div>
            <div class="regulationsTip">登录即表示您已阅读并同意 <router-link class="bluetext" target="_blank" :to="{path:'/regulation'}">服务条款</router-link> </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            showlogin:false,
            closeeye:require('@/assets/img/pcImg/loginImg/closeeye.png'),
            openeye:require('@/assets/img/pcImg/loginImg/openeye.png'),
            changeeye:false,
            passwordtype:'password',
            username: '',
            password: '',
            ctrlMenu:false,
            logo:require('@/assets/img/mobileImg/headImg/logo.png'),
            clickImg:require('@/assets/img/mobileImg/headImg/clickImg.png'),
            nav:[
               {
                   id:100,
                   name:'登录',
                   link:'/login',
               },
               {
                   showchild:false,
                   classname:'nav2',
                   id:66,
                   name:'产品',
                   link:'/product/1',
                   down:require('@/assets/img/mobileImg/headImg/down.png'),
                   up:require('@/assets/img/mobileImg/headImg/up.png'),
                   childlist:[
                       {
                            id:1,
                            name:'医废管理',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-yf.png"),
                        },
                        {
                            id:2,
                            name:'输送系统',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-ss.png"),
                        },
                        {   
                            showGrandChild:false,
                            classname:'nav3',
                            id:666,
                            name:'维修系统',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-wx.png"),
                            up:require("@/assets/img/pcImg/leftnavImg/up.png"),
                            down:require("@/assets/img/pcImg/leftnavImg/down.png"),
                            childlist:[
                                {
                                    id:31,
                                    name:'维修报障',
                                },
                                {
                                    id:32,
                                    name:'维修巡检',
                                },
                                {
                                    id:33,
                                    name:'后勤维保',
                                }
                            ]
                        },
                        {
                            id:4,
                            name:'保洁系统',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-bj.png"),
                        },
                        {
                            id:5,
                            name:'人员管理',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-ry.png"),
                        },
                        {
                            id:6,
                            name:'考勤打卡',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-kq.png"),
                        },
                        {
                            id:7,
                            name:'停车场管理',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-tc.png"),
                        },
                        {
                            id:8,
                            name:'智能卫生间引导系统',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-zn.png"),
                        },
                        {
                            id:9,
                            name:'服务满意度管理',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-fw.png"),
                        },
                        {
                            id:10,
                            name:'保安巡逻',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-ba.png"),
                        },
                        {
                            id:11,
                            name:'被服管理',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-bf.png"),
                        },
                        {
                            id:12,
                            name:'垃圾分类小游戏',
                            icon:require("@/assets/img/pcImg/headImg/nav-icon-lj.png"),
                        },
                        
                   ],
               },
               {
                   id:-3,
                   name:'产品发布',
                   link:'/release',
               },
               {
                   showchild:false,
                   down:require('@/assets/img/mobileImg/headImg/down.png'),
                   up:require('@/assets/img/mobileImg/headImg/up.png'),
                   classname:'nav22',
                   id:6666,
                   name:'定制服务',
                   link:'/customMade/13',
                   childlist:[
                        {
                            id:23,
                            name:'软件定制',
                            icon:require("@/assets/img/pcImg/leftnavImg/sidebar／icon／Custom.png"),
                        },
                        {
                            id:24,
                            name:'动漫设计',
                            icon:require("@/assets/img/pcImg/leftnavImg/sidebar／icon／anime.png"),
                        }

                   ]
               },
               {
                   id:-4,
                   name:'下载',
                   link:'/down',
               },
               {
                   id:-5,
                   name:'关于我们',
                   link:'/about',
               },
               
           ],
        }
    },
    props:["changeTop","closeTop"],
    methods:{
        showMenu(){
            this.ctrlMenu = !this.ctrlMenu
            // this.changeTop = true
            if(this.ctrlMenu == true){
                document.body.style.position = 'fixed'
                document.body.style.overflow = 'hidden'
            }
            else{
                document.body.style.overflow = 'scroll'
                document.body.style.position = 'relative'
            }
            this.nav[1].showchild = this.nav[1].childlist[2].showGrandChild = this.nav[3].showchild = false
            
        },
        gogogo(id,link){
            if(id == 100){
                this.showlogin = true
                return this.showMenu()
            }else if(id == -4){
                window.location.href = 'https://d.eai9.com/index.html'
                return this.showMenu()
            }else if(id < 0){
                this.$router.push({path:link});
                return this.showMenu()
            }else if(id == 66){
                this.nav[1].showchild = !this.nav[1].showchild
            }else if(id == 666){
                this.nav[1].childlist[2].showGrandChild = !this.nav[1].childlist[2].showGrandChild
            }
            else if(id == 6666){
                this.nav[3].showchild = !this.nav[3].showchild
            }else if(20 < id && id < 30){
                this.$router.push({
                    path:`/customMade/${id}`,
                })
                return this.showMenu()
            }else{
                this.$router.push({
                    path:`/product/${id}`,
                })
                return this.showMenu()
            }
           
            
        },
        login(){
            if(this.username != '' && this.password != ''){
                window.location.href = 'http://say.ektlang.com/?name=' + this.username + '&pswd=' + md5(this.password)
            }
        }
    }
    
}
</script>

<style lang="less" scoped>
.head{
    // overflow: auto;
    .headbox{
        height: 2.75rem;
        width: 23.4375rem;
        position: fixed;
        z-index: 100;
        .logo{
            float: left;
            width: 5.625rem;
            height: 1.875rem;
            margin: 0.5rem 0 0 0.9375rem;
            img{
                width: 100%;
            }
        }
        .menubtn{
            float: right;
            width: 1.5rem;
            height: 1.0625rem;
            margin: 0.875rem 1.1875rem 0 0;
            img{
                width: 100%;
            }
        }
    }
    .navbg{
        height: 100%;
        width: 23.4375rem;
        background-color: rgba(49,56,78,0.65);
        position: fixed;
        z-index: 90;
    }
    .nav{
        overflow: auto;
        z-index: 200;
        position: fixed;
        top: 2.75rem;
        left: 5.625rem;
        width: 17.8125rem;
        height: calc(100% - 2.75rem);
        background-color: #ffffff;
        .nav1{
            overflow: auto;
            li{
                // height: 3.125rem; 
                line-height: 3.125rem;
                border-bottom: 0.0625rem solid #EEEEEE;
                font-size: 1.125rem;
                font-weight: 400;
                color: #31384E;
                span{
                    margin-left: 1.125rem;
                }
                img{
                    float: right;
                    margin: 1.3125rem 0.9375rem 0 0;
                }
            }
            li:first-child{
                line-height: 5rem;
                color: #207BFA;
            }
            .nav2,.nav22{
                padding: 1.5625rem 0;
                background: #F2F2F2;
                li{
                    border: none;
                    width: 15.9375rem;
                    height: 2.875rem;
                    background: #FFFFFF;
                    opacity: 1;
                    border-radius: 6px;
                    margin: 0 0 0.625rem 0.9375rem;
                    img{
                        float: left;
                        margin: 0.5rem 0.625rem 0 0.625rem;
                    }
                    span{
                        margin: 0;
                        line-height: 2.875rem;
                        float: left;
                        font-size: 1.0625rem ;
                        font-weight: 400;
                        color: #31384E;
                    }
                    .ctrlicon{
                        float: left;
                        margin: 1.25rem 0 0 1rem;
                    }
                }
                li:last-child{
                    margin-bottom: 0;
                }
                .nav3{
                     height: 11.5rem ;
                     ul{
                         float: left;
                         margin-left: 3.125rem;
                         li{
                             border-radius: none;
                             width: 12rem;
                             margin: 0;
                         }
                     }
                }
                .showgrandchild{
                    height: 2.875rem;
                }
            }
        }
        
    }
}
.changeheadsize{
    height: 100%;
}
.changetop{
    .headbox{
        background: #2062EB;
    }
}
.closetop{
    display: none;
}

//login
input::-webkit-input-placeholder {
color:#697598; /*WebKit browsers*/
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 4 to 18 */
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 19+ */
}
input::-ms-input-placeholder {
color:#697598 ; /*Internet Explorer 10+*/
}
.login{
    width: 100%;
    height: 100%;
    .loginbg{
        position: fixed;
        z-index: 1000;
        background-color: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
    }
    .loginbox{
        width: 21.5625rem;
        height: 22rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.9375rem 2.6875rem rgba(0, 0, 0, 0.05);
        border-radius: 0.375rem;
        top: 9rem;
        left: 50%;
        margin-left: -10.7rem;
        position: fixed;
        z-index: 2000;
        h2{
            font-size: 1.5rem;
            font-weight: 400;
            color: #31384E;
            float: left;
            margin: 2.25rem 0 2.4375rem 7.5625rem;
        }
        .inputstr{
            width: 18.8125rem;
            height: 3.0625rem;
            background: #FBFBFB;
            border-radius: 0.375rem;
            float: left;
            margin: 0 0 1.6875rem 1.375rem;
            input{
                width: 16rem;
                height: 1.3125rem;
                font-size: 1rem;
                font-weight: 400;
                color:  black;
                opacity: 0.9;
                margin: 1rem 0 0 0.625rem;
                background-color:  #FBFBFB;
                float: left;
            }
            
        }
        #passwordbox{
                    img{
                        float: left;
                        width: 1.6875rem;
                        margin: 0.8rem 0 0 0;
                    }
                }
        .inputput{
            float: left;
            width: 18.8125rem;
            height: 3.125rem;
            background: linear-gradient(132deg, #2655EE 0%, #34B3FF 100%);
            box-shadow: 0rem 0.125rem 1rem rgba(32, 123, 250, 0.5);
            border-radius: 0.375rem;
            line-height: 3.125rem;
            margin: 0 0 0 1.375rem;
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            color: #FFFFFF;
        }
        .regulationsTip{
                float: left;
                font-size: 0.5rem;
                color: #31384E;
                height: 1rem;
                line-height: 1rem;
                margin: 0.5rem 0 0 4rem;
                .bluetext{
                    color: #207bfa;
                    cursor: pointer;
                }
            }
    }
}
</style>