<template>
  <div>
      <div class="pc-top" :class="{changetop:changeTop,closetop:closeTop}" id="top">
          <div class="head">
              <div class="logo">
                  <router-link to="/"><img :src="logo" alt=""></router-link>
              </div>
              <div class="nav">
                  <div class="nav1">
                      <ul v-for="(item,index) in nav1" :key="index" 
                            @mouseenter="isShowNav2(item.id)" 
                            @mouseleave="showNav2 = false"
                            :class="{hasline:item.link.indexOf(this.currentName) > 0}"
                             @click="gogogo(item.link)">
                          <!-- <router-link :to="item.link"> -->
                              <!-- :to="/product/1" -->
                          <!-- <router-link :to="item.link"> -->
                            <li>
                                {{item.name}}
                            </li>
                          <!-- </router-link> -->
                      </ul>
                  </div>
                  <div class="nav2" v-show="showNav2" @mouseenter="isShowNav2(1)" @mouseleave="showNav2 = false">
                      <div class="title">
                          <h2>泰科医键通&nbsp;&nbsp;后勤一体化信息平台</h2>
                      </div>
                      <ul>
                          <li v-for="(item,index) in nav2" :key="index">
                              <router-link :to="item.link">
                                <div>
                                    <img :src="item.img" alt="">
                                    <span>{{item.str}}</span>
                                </div>
                              </router-link>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      <div class="login" v-show="showlogin">
        <div class="loginbg" @click="showlogin = false"></div>
        <div class="loginmain">
                <h2>账号登录</h2>
                <div class="inputstr">
                    <input v-model="username" type="text" name="" id="" placeholder="账号">
                </div>
                <div class="inputstr" id="passwordbox">
                    <input v-model="password" :type="passwordtype" name="" id="" placeholder="密码">
                    <img :src="closeeye" alt="" 
                        v-show="changeeye == false"
                        @click="changeeye = true,passwordtype = 'text'">
                    <img :src="openeye" alt="" 
                        v-show="changeeye == true"
                        @click="changeeye = false,passwordtype = 'password'">
                </div>
                <div class="inputput" @click="login">登录</div>
                <div class="regulationsTip">登录即表示您已阅读并同意 <router-link class="bluetext" target="_blank" :to="{path:'/regulation'}">服务条款</router-link> </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
   name:'Head',
   data(){
       return{
           closeeye:require('@/assets/img/pcImg/loginImg/closeeye.png'),
           openeye:require('@/assets/img/pcImg/loginImg/openeye.png'),
           changeeye:false,
           passwordtype:'password',
           username: '',
           password: '',
           showlogin:false,
           showNav2:false,
           currentName:'',
           logo:require('@/assets/img/pcImg/headImg/logo.png'),
           nav1:[
               {
                   id:1,
                   name:'产品',
                   link:'/product/1',
               },
               {
                   id:2,
                   name:'定制服务',
                   link:'/customMade/13',
               },
               {
                   id:3,
                   name:'下载',
                   link:'/down',
               },
               {
                   id:4,
                   name:'产品发布',
                   link:'/release',
               },
               {
                   id:5,
                   name:'关于我们',
                   link:'/about',
               },
               {
                   id:6,
                   name:'登录',
                   link:'/login',
               },
           ],
           nav2:[
               {
                  id:1,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-yf.png"),
                  str:'医废管理',
                  link:'/product/1',
                },
                {
                  id:2,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-tc.png"),
                  str:'停车场管理',
                  link:'/product/7',
                },
                {
                  id: 3,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-ss.png"),
                  str:'输送系统',
                  link:'/product/2',
                },
                {
                  id:4,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-zn.png"),
                  str:'智能卫生间引导系统',
                  link:'/product/8',
                },
                {
                  id:5,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-wx.png"),
                  str:'维修系统',
                  link:'/product/31',
                },
                {
                  id:6,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-fw.png"),
                  str:'服务满意度管理',
                  link:'/product/9',
                },
                {
                  id:7,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-bj.png"),
                  str:'保洁系统',
                  link:'/product/4',
                },
                {
                  id:8,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-ba.png"),
                  str:'保安巡逻',
                  link:'/product/10',
                },
                {
                  id:9,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-ry.png"),
                  str:'人员管理',
                  link:'/product/5',
                },
                {
                  id:10,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-bf.png"),
                  str:'被服管理',
                  link:'/product/11',
                },
                {
                  id:11,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-kq.png"),
                  str:'考勤打卡',
                  link:'/product/6',
                },
                {
                  id:12,
                  img:require("@/assets/img/pcImg/headImg/nav-icon-lj.png"),
                  str:'垃圾分类小游戏',
                  link:'/product/12',
                }
           ],
       }
   },
   props:["changeTop","closeTop"],
   methods:{
       isShowNav2(id){
           if(id == 1){
               this.showNav2 = true
           }
       },
       sendId(id){
            this.$router.push({
                path:`/product/${id}`,
            })
       },
       gogogo(link){
           if(link == '/login'){
               this.showlogin = true
           }else if(link == '/down'){
               window.location.href = 'https://d.eai9.com/index.html'
           }else{
               this.$router.push({path:link});
           }
           
       },
       login(){
            if(this.username != '' && this.password != ''){
                window.location.href = 'http://say.ektlang.com/?name=' + this.username + '&pswd=' + md5(this.password)
            }
       }
   },
    watch:{
        $route:{
            handler(){
                console.log(this.$route.name);
                this.currentName = this.$route.name
            },
            immediate: true
        },
    },
}
</script>

<style lang="less" scoped>
.pc-top{
    width: 100%;
    height: 90px;
    // background-color: rgb(124, 40, 40);
    position: fixed;
    z-index: 100;
    .head{
        width: 1200px;
        height: 70px;
        margin: 0 auto 0;
        // background-color: rgb(114, 51, 196);
        position: relative;
        top: 20px;
        .logo{
            float: left;
            height: 50px;
            margin: 9px 0 0 0;
            // background-color: #fff;
            img{
                height: 100%;
            }
        }
        .nav{
            .nav1{
                float: right;
                ul{
                    display: inline-block;
                    // background-color: rgb(95, 114, 11);
                    margin-left: 40px;
                    height: 70px;
                    text-align: center;
                    color: #fff;
                    cursor:pointer;
                    li{
                        font-size: 18PX;
                        height:40px;
                        line-height: 40px;
                        margin: 15px 0 0 0;
                    }
                }
                ul:hover{
                    border-bottom: 2px solid #FFFFFF;
                }
                .hasline{
                    border-bottom: 2px solid #FFFFFF;
                }
                ul:last-child{
                    width: 110px;
                    border-bottom: none;
                    li{
                        height: 40px;
                        line-height: 40px;
                        border-radius: 21px;
                        // background-color: bisque;
                        border: #fff solid 1px;
                    }
                    li:hover{
                        background-color: #fff;
                        color: #4B94FB;
                    }
                }
                
            }
            .nav2{
                position: absolute;
                top: 73px;
                left: 710px;
                width: 440px;
                height: 396px;
                background-color: #FEFEFE;
                box-shadow: 5px 12px 38px rgba(16, 38, 220, 0.45);
                border-radius: 3px;
                .title{
                    height: 45px;
                    width: 100%;
                    border-bottom: 1px solid #E9EFFF;
                    h2{
                        height: 45px;
                        line-height: 45px;
                        font-size: 16px;
                        color: #31384E;
                        margin: 0 0 0 103px;
                    }
                   
                }
                ul{
                    li{
                        display: inline-block;
                        width: 190px;
                        height: 46px;
                        border-radius: 6px;
                        margin: 10px 0 0 20px;
                        // background-color: #fff;
                        img{
                            vertical-align: middle;
                            margin: 0 10px;
                        }
                        span{
                            font-size: 14px;
                            color: #31384E;
                            height: 46px;
                            line-height: 46px;
                        }
                    }
                    li:hover{
                        background-color: #fff;
                        box-shadow: 0px 10px 42px rgba(23, 35, 75, 0.08);
                    }
                   
                }
            }
        }
    }
}
.changetop{
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
    opacity: 0.95;
    .head{
        position: fixed;
        top: 0;
        left: 50%;
        margin: 0 0 0 -600px;
        // background-color: rgb(8, 87, 48);
        .nav{
            .nav1{
                li{
                    color: #000 !important;
                }
                
                ul:last-child{
                    width: 110px;
                    border-bottom: none;
                    li{
                        height: 40px;
                        line-height: 40px;
                        border-radius: 21px;
                        // background-color: bisque;
                        border: rgb(0, 0, 0) solid 1px;
                        
                    }
                    li:hover{
                        background-color: #fff;
                        color: #4B94FB;
                         border:  #4B94FB solid 1px;
                    }
                }
            }
        }
    }
}
.closetop{
    display: none;
}

//login
input::-webkit-input-placeholder {
color:#697598; /*WebKit browsers*/
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 4 to 18 */
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 19+ */
}
input::-ms-input-placeholder {
color:#697598 ; /*Internet Explorer 10+*/
}
.login{
    margin: 0;
    padding: 0;
    .loginbg{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 1000;
    }
    .loginmain{
            width: 380px;
            height: 379px;
            background: #FFFFFF;
            box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            position: fixed;
            z-index: 1000;
            top: 300px;
            left: 50%;
            margin: 0 0 0 -190px;
            h2{
                font-size: 28px;
                font-weight: 400;
                line-height: 37px;
                color: #31384E;
                -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
                opacity: 1;
                margin: 40px 0 40px 134px;
            }
            .inputstr{
                width: 332px;
                height: 54px;
                background: #FBFBFB;
                opacity: 1;
                border-radius: 6px;
                margin: 0 0 30px 24px;
                input{
                    width: 280px;
                    height: 21px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    color:  black;
                    opacity: 0.9;
                    margin: 17px 0 0 10px;
                    background-color:  #FBFBFB;
                    // border: none;
                    // outline:none;
                }
                
            }
            #passwordbox{
                    img{
                        position: relative;
                        top: 10px;
                        cursor:pointer;
                    }
                }
            .inputput{
                width: 332px;
                height: 54px;
                background: linear-gradient(132deg, #2655EE 0%, #34B3FF 100%);
                box-shadow: 0px 2px 16px rgba(32, 123, 250, 0.5);
                border-radius: 6px;
                margin: 0 0 0 24px;
                cursor:pointer;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 54px;
            }
            .inputput:hover{
                box-shadow:none;
            }
            .regulationsTip{
                text-align: center;
                font-size: 14px;
                color: #31384E;
                height: 30px;
                line-height: 30px;
                .bluetext{
                    color: #207bfa;
                    cursor: pointer;
                }
            }
    }
    
}
</style> 