<template>
    <div>
        <heads :changeTop="changetop" :closeTop="closetop"/>
        <router-view/>
    </div>
</template>

<script>
import './rem'
import Heads from '@/components/mobile/mhead&mlogin.vue'
export default {
    name:"mApp",
    components:{
        Heads,
    },
    data(){
        return{
            changetop:false,
            closetop:false,
        }
    },
    watch:{
            $route:{
                handler(){
                    document.body.scrollTop = document.documentElement.scrollTop = 0
                    // console.log(this.$route.name);
                    if(this.$route.name == 'download' || this.$route.name == 'regulation'){
                    this.closetop = true
                    }else{
                    this.closetop = false
                    }

                },
                immediate: true
            },
        },
    mounted() {
        window.addEventListener('scroll',this.handleScroll);   // 监听（绑定）滚轮滚动事件
    },
    methods:{
        handleScroll() {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollTop > 2){
                    // console.log(scrollTop);
                    this.changetop = true
                }else{
                    // console.log(1)
                    this.changetop = false
                }
        },
    },
}
</script>

<style lang="less" scoped>
*{
    margin: 0;
    padding: 0;
}
</style>