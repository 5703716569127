import { createApp } from 'vue'
import App from './App.vue'
import mApp from './mApp.vue'
import router from './router'
import store from './store'
import mrouter from './mrouter'

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
  });

  function _isMobile() {//判断是否为手机端
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  }
  
  if(_isMobile()){
    console.log('手机端')
    createApp(mApp).use(store).use(mrouter).mount('#app')
  }
  else{
    console.log("pc端")
    createApp(App).use(store).use(router).mount('#app')
  }