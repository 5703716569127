<template>
    <div class="foot">
        <div class="content">Copyright@2016 www.ektlang.com
          <span>医键通版权所有 
            <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备 16077822号</a> 
            <!-- <a href="https://beian.miit.gov.cn/" target="_blank">、粤ICP备 16077822号-2</a> -->
          </span> 
        </div>
    </div>
</template>

<script>
export default {
    name:'foots',
}
</script>

<style lang="less" scoped>
.foot{
  height: 80px;
  background: #FAFBFE;
  opacity: 1;
  border-radius: 3px;
  width: 100%;
  .content{
    margin: 0 auto;
    width: 1006px;
    height:80px;
    line-height:80px;
    text-align: center;
    color: #1D1D1D;
    font-size: 14px;
    a {
      color: #727b86;
    }
  }
}
</style>